import React, { Component } from 'react';
import Image from '../ResuableImageWrapper';
import styled from 'styled-components';
import parse from 'html-react-parser';
import Chevron from '../../../assets/itemised-slider_chevron.inline.svg'
import VisibilitySensor from 'react-visibility-sensor';
import Slider from "react-slick";
import "../../../sass/components/reuseable_components/_itemised_slider.scss";


class CaseStudyTestimonial extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      pagination:null,
      slideIndex: 0,
      updateCount: 0,
      isInView: false,
    };

  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });

    if(this.props.isInView === 'is_inview'){
        this.setState({
          wasInView: true
        })
      }
  }



  render() {
    const data = this.props;
  
      let classList = [
        'itemised_slider',
        // 'is_inview'
      ]; 

      const removeBottomMargin = data.removeBottomMargin;
      const slider = data.slider;

      if(removeBottomMargin === true){
        classList.push('no-margin');
      }
      
      const inviewOnChange = (isVisible) => {
        this.setState({
          isInView: isVisible})
      }

    var settings = {
      afterChange: (current, next) => {
        
        this.setState(state => ({ 
          updateCount: state.updateCount + 1, 
        }))

        // Update custom pagination active
        this.pagination.querySelector(`li.active`).classList.remove('active');
        this.pagination.querySelector(`li:nth-child(${current + 1})`).classList.add('active');
      },

      beforeChange: (current, next) => {
        // set index state
        //console.log('currentIndexInState:',this.state.slideIndex, 'next:',next, 'currentIndex:',current)
        this.setState({ slideIndex: next });
        //console.log('currentIndexInState:',this.state.slideIndex, 'next:',next, 'currentIndex:',current)
      },

      onInit: () => {
        setTimeout(function(){
          let tallest = 0;
          
          if(typeof document !== 'undefined'){
            const slides = Array.from(document.querySelectorAll('.slider-1 .slick-slide .text-wrapper .text p'));
                            
            slides.map((slide) => {
              setTimeout(function(){
                let slideHeight = parseInt(window.getComputedStyle(slide).height.slice(0, -2));
                if(slideHeight > tallest){
                  tallest = slideHeight;
                } 
              },500)
            });
  
            setTimeout(function(){
              document.querySelector('.slider_wrap').style.minHeight = tallest+'px';
            },600)
          }
        }.bind(this),100)
        
      }
    };
    
    return (
      <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!this.state.isInView}  onChange={inviewOnChange}>
      {({isVisible}) =>
      
        <section className={`${this.state.isInView === true ? 'is_inview' : ''} ${classList.join(" ") }`}>
          <div className="slider_wrapper">
            <ul className="pagination"  ref={pagination => (this.pagination = pagination)}>
              {
                slider.map((slide, index) => {
                  return(
                    <li className={index == 0 ? 'active' : ""} key={`slider_pagination_index_${index}`}>{index+1}</li>
                  )
                })
              }
            </ul>
            
            <div className="divider">
              <span className="outer">
                <span className="text">{data.sliderLabel}</span>
              </span>
            </div>
            
            <div className="slider_wrap" >
              <div className="controls">
                <div className="prev nav_arrow"
                onClick={() => {
                  this.slider1.slickGoTo(this.state.slideIndex-1); 
                  this.slider2.slickGoTo(this.state.slideIndex-1);
                }}>
                  
                  <Chevron 
                    value={this.state.slideIndex} 
                  />
                </div>

                <div className="next nav_arrow"
                 onClick={(e) => {
                  this.slider1.slickGoTo(this.state.slideIndex+1); 
                  this.slider2.slickGoTo(this.state.slideIndex+1);
                }} >
                  <Chevron 
                    value={this.state.slideIndex} 
                    />
                </div>
              </div>

              <div className="main_text_wrap slider-1">
                <Slider
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)} 
                slidesToShow={1}
                slidesToScroll={1}
                vertical={true}
                fade={true}
                arrows={false}
                adaptiveHeight={true}
                touchMove={false}
                draggable={false}
                focusOnSelect={true}
                autoplaySpeed={5000}
                accessibility={false}
                {...settings}
                className="main_text_inner">
                  {
                    slider.map((slide, index) => {
                      return(
                          <div className={index == 0 ? 'active' : ""} key={`slider_main_text_${index}`}>
                            <div className="text-wrapper">
                              <span className="text">{parse(slide.text)}</span>
                              <span className="number">{index+1}</span>
                            </div>
                          </div>
                      )
                    })
                  }
                </Slider>
              </div>

              <div className="image-wrap-outer slider-2">            
                <Slider   
                asNavFor={this.state.nav1}
                ref={slider => (this.slider2 = slider)}
                slidesToShow={1}
                slidesToScroll={1}
                arrows={false}
                fade={true}
                swipeToSlide={true} 
                accessibility={false}
                className="image-wrap-inner">  
                  {
                    slider.map((slide, index) => {
                      return(
                        <div className="the-image" key={`slider_image_${index}`}>
                          <div className="image-wrapper">
                            <Image 
                            image={slide?.image?.localFile?.childImageSharp?.fluid}
                            objectFit={slide?.imageType}
                            alt={slide?.image?.altText}
                            />
                          </div>
                        </div>
                      )
                    })
                  }    
                </Slider>
              </div>
            </div>
          </div>
        </section>
      }
    </VisibilitySensor>
    );
  }
}


export default CaseStudyTestimonial

